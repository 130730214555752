import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Form,
    Col,
    FormGroup,
    Button,
    InputGroup,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { ReactSession } from 'react-client-session';

const CreateProductForm = () => {
    const { handleSubmit, register, unregister, control, watch } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let [categories, setCategories] = useState([]);
    let [types, setTypes] = useState([]);
    let [type, setType] = useState('');
    let [category, setCategory] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false);
    let [updateLoad, setUpdate] = useState(false);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const history = useHistory();
    let [fileName, setFileName] = useState('');
    let [file, setFile] = useState('');
    let [invalidFile, setInvalidFile] = useState(false);
    let [schools, setSchools] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchSchool, setSearchSchool] = useState('');
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [schoolPricesList, setSchoolPricesList] = useState([]);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const token = ReactSession.get("token");
    let config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    useEffect(() => {
        getCateogryData();
        getTypes();
        getSchoolData();
    }, []);

    useEffect(() => {
        setFilteredSchools(
            schools
                .filter(school => school.name.toLowerCase().includes(searchSchool.toLowerCase()))
                .filter(school => !schoolPricesList.some(item => item.school?.id === school.id))
        );
    }, [schools, searchSchool, schoolPricesList]);

    const getCateogryData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/categories', config);
            setCategories(res.data);
        } catch (error) {
            setCategories([]);
        }
    };

    const getTypes = async () => {
        try {
            let res = await axios.get(BASE_URL + '/types', config);
            setTypes(res.data);
        } catch (error) {
            setTypes([]);
        }
    };

    const getSchoolData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/schools', config);
            setSchools(res.data.data);
        } catch (error) {
            setSchools([]);
        }
    };

    const createProduct = async (data) => {
        if (category.length === 0) {
            return;
        }
        let productData = {
            name: data.name,
            price: data.price,
            cost: data.cost,
            categoryId: category,
            typeId: type,
            description: data.description,
            calories: data.calories,
            carbs: data.carbs,
            fats: data.fats,
            proteins: data.proteins,
            schoolPrices: Object.fromEntries(
                Object.entries(data.schoolPrices || {})
                    .filter(([key, value]) => !isNaN(key) && value !== undefined && value !== '' && key !== undefined && key !== '') 
                    .map(([key, value]) => [key, Number(value)])
            )
        };
        if (file) {
            var res = await axios.post(BASE_URL + '/product/create', productData);
            if (res.status === 200) {
                let productID = res.data.id;
                const formData = new FormData();
                formData.append('product_img', file);
                await axios.put(BASE_URL + `/product/${productID}/image`, formData);
                history.push(`/admin/product/${res.data.id}`);
            } else {
                // Add some error handling.
            }
        } else {
            setInvalidFile(true);
        }
    };

    const handleFileChange = async (evt) => {
        setFile(evt.target.files[0]);
    };

    const handleSchoolPriceDelete = (index) => {
        const newSchoolPricesList = [...schoolPricesList];
        newSchoolPricesList.splice(index, 1);
        setSchoolPricesList(newSchoolPricesList);
    };

    return (
        <>
            <UserHeader />
            <Container fluid className="mt--7">
                <Row className='align-items-center justify-content-center'>
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Create Product</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    {/* <h4>Category: {product.category.name}</h4> */}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit(createProduct)}>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">Name</label>
                                                <input className="form-control" id="input-first-name" placeholder="Product name" type="text" {...register('name')} required />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="product_price">Price</label>
                                                <input className="form-control" id="product_price" placeholder="Product Price" type="text" required {...register('price')} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="12">
                                            <FormGroup>
                                                <Button
                                                    color="success"
                                                    onClick={() => setSchoolPricesList([...schoolPricesList, { school: null, price: 0 }])}
                                                    disabled={schoolPricesList.length >= schools.length}
                                                    style={{ backgroundColor: schoolPricesList.length >= schools.length ? '#A8ABAD' : undefined }}
                                                >
                                                    Add School
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                        {schoolPricesList.length > 0 && <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="school_prices">School Prices</label>
                                                {schoolPricesList.map((item, index) => (
                                                    <Row className="align-items-center" key={index}>
                                                        <Col xs="6" className="mb-3">
                                                            <Dropdown isOpen={dropdownOpen === index} toggle={() => setDropdownOpen(dropdownOpen === index ? null : index)} style={{ width: '100%' }}>
                                                                <DropdownToggle caret style={{ width: '100%' }}>
                                                                    {item.school ? item.school.name : 'Select school'}
                                                                </DropdownToggle>
                                                                <DropdownMenu style={{ width: '100%' }}>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Search school"
                                                                        value={searchSchool}
                                                                        onChange={(e) => setSearchSchool(e.target.value)}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                    {filteredSchools.map((school) => (
                                                                        <DropdownItem key={school.id} onClick={() => {
                                                                            const newSchoolPricesList = [...schoolPricesList];
                                                                            newSchoolPricesList[index].school = school;
                                                                            setSchoolPricesList(newSchoolPricesList);
                                                                            setDropdownOpen(null);
                                                                        }} style={{ width: '100%' }}>
                                                                            {school.name}
                                                                        </DropdownItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs="4" className="mb-3">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder={item.school ? `Price for ${item.school.name}` : 'Price for selected school'}
                                                                defaultValue={watch('price')}
                                                                {...register(`schoolPrices.${item.school?.id}`, { valueAsNumber: true })}
                                                            />
                                                        </Col>
                                                        <Col xs="2" className="mb-3">
                                                            <Button color="danger" onClick={() => {
                                                                handleSchoolPriceDelete(index);
                                                                unregister(`schoolPrices.${item.school?.id}`);
                                                            }}>
                                                                Delete
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </FormGroup>
                                        </Col>}
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="calories">Calories</label>
                                                <input className="form-control" id="calories" placeholder="" type="text" {...register('calories')} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="cost">Base Cost</label>
                                                <input className="form-control" id="cost" placeholder="" type="text" {...register('cost')} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="carbs">Carbs</label>
                                                <input className="form-control" id="carbs" placeholder="Calculated Carb Macros" type="text" {...register('carbs')} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="fats">Fats</label>
                                                <input className="form-control" id="fats" placeholder="Calculated Fat Macros" type="text" {...register('fats')} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="proteins">Proteins</label>
                                                <input className="form-control" id="proteins" placeholder="Calculated Protein Macros" type="text" {...register('proteins')} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="7">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="description">Description</label>
                                                <input className="form-control" id="description" placeholder="Your Product Description" type="text" required {...register('description')} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <label className="form-control-label" htmlFor="basic-url">Category</label>
                                            <InputGroup>
                                                <Input id="category-dropdown" name="category" type="select" required value={category.name} onChange={e => { e.preventDefault(); setCategory(e.target.value); }}>
                                                    <option value="0">Select a Category</option>
                                                    {categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <label className="form-control-label" htmlFor="basic-url">Type</label>
                                            <InputGroup>
                                                <Input id="type-dropdown" name="type" type="select" required value={type.name} onChange={e => { e.preventDefault(); setType(e.target.value); }}>
                                                    <option value="0">Select a Type</option>
                                                    {types.map((typ) => <option key={typ.id} value={typ.id}>{typ.type_name}</option>)}
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="form-control-label ml-3" htmlFor="productImage">Product File</label>
                                            <br />
                                            <input className='ml-3' type='file' accept='.png,.jpg,.jpeg' onChange={handleFileChange}></input>
                                            <br />
                                            <span className='ml-4 my-4' color="danger">{invalidFile ? 'Make sure to provide a valid file' : ''}</span>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        {updateLoad ? <span>...</span> : <Col lg="6"><FormGroup><Button type="submit" color="success">Create</Button></FormGroup></Col>}
                                    </Row>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </>
    );
};

export default CreateProductForm;

